*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
.bg_tracking{
  background-color: #000D13;
}

.bg_primary {
  background-color: #041E51;
  border-color: #041E51;
}
.bg_primary:hover {
  background-color: #02173f;
  border-color: #02173f;
}