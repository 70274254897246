#nav-header>.nav.nav-tabs {
  border-bottom: 1px solid #949494;
}

#nav-header>.nav.nav-tabs>.nav-item>.nav-link {
  border: none;
  font-weight: 600;
  color: #949494;
}

#nav-header>.nav.nav-tabs>.nav-item>.nav-link.active {
  border-bottom: 2px solid black;
  border-radius: 0 !important;
  color: black;
}

.school-photo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}