.shimmer_animate {
  animation : shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
   background-size: 1000px 100%;
}

@keyframes shimmer {
 0% {
   background-position: -1000px 0;
 }
 100% {
   background-position: 1000px 0;
 }
}

.filter-link .item {
  text-decoration: none;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem 0;
  color: #949494;
  font-weight: 600;
}
.filter-link .item.active {
  color: #000000;
  background-color: #FFFFFF;
}
.filter-search {
  background-color: #E8ECF9;
  border-radius: 0.25rem;
  padding: 0 0.50rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.filter-search input {
  border: none;
  outline: none;
  padding: 0.25rem;
  background-color: transparent;
  width: 100%;
}
.filter-search svg{
  width: 1.25em;
  height: 2em;
  fill: #949494;
}
.filter-search input:focus + svg {
  fill: #000000;
}


.course {
  width: 45%;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 0.75rem;
}
.course:hover {
  cursor: pointer;
}
.course-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.university {
  width: 60px;
}
.name {
  font-weight: 700;
  display: block;
}
.degree {
  font-weight: 600;
  display: block;
}
.duration {
  font-size: 0.75rem;
  display: block;
}



.view-course .legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #BFC9ED;
}
.view-course .legend .name {
  font-weight: 600;
}
.description {
  position: relative;
  height: 3.75rem;
  overflow: hidden;
  margin-top: 0.5rem;
}
.description > p {
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
.objectives-list {
  list-style: none;
  padding-left: 0;
}
.objectives-list > li{
  position: relative;
  height: 1.2rem;
  overflow: hidden;
}
.objectives-list > li > span{
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
button {
  border-radius: 0.25rem !important;
  border-color: #041E51;
  padding: 0.125rem 0.5rem;
  background-color: #041E51;
  color: #FFFFFF;
}