.course-header {
    border: 1px solid #e4e8f7;
    width: 100%;
    padding: 0.25rem;
    cursor: default;

    display: flex;
    justify-content: space-between;
}

.course-header>.course {
    margin-bottom: 0;
}

.course-header>.course>.university {
    width: 70px;
}

.course-header>.progress-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-right: 0.5rem;
}

#progress-label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

#progress-bar {
    height: 0.25rem;
    width: 100%;
    background-color: #96a6e0;
}

#progress-bar-content {
    background-color: #069948;
    height: 0.25rem;
    width: 75%;
}

.course-description{
    border-left: 1px solid #e4e8f7;
}